<template>
  <div>
    <Header back="返回" title="工程专利" index="首页" titleOne="案卷管理" beforeTitle="案卷导入" />
    <div class="content">
      <div class="ImportTop">
        <el-upload
          class="upload-demo"
          ref="imgupload"
          action="string"
          :http-request="httpRequest"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
        >
          <el-button type="primary" class="ChooseFile">请上传附件</el-button>
        </el-upload>
        <span style="margin-left: 20px;color: #A6ABC7;">附件上传文件：只支持excel格式。</span>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="Loading···"
        :data="fileList"
        style="width: 100%;margin-top: 40px;"
      >
        <el-table-column prop="file_name" label="附件名称" :show-overflow-tooltip="true" width="700">
          <template slot-scope="scope">
            {{
            scope.row.file_name ? scope.row.file_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="上传时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.create_time ? scope.row.create_time : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="finish_time" label="完成时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.finish_time ? scope.row.finish_time : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="附件" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.status != 1">未执行</span>
            <span style="color: #5cc038;" v-if="scope.row.status == 1">已完成</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      fileList: [],
      loading: false,//表格动画默认false
      annexParams: {
        page: 1,
        limit: 10,
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.axios.get('/api/project_patent/import_list', { params: { ...this.annexParams } }).then((res) => {
        this.fileList = res.data.data
      })
    },
    httpRequest (param) {
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      this.upload("/api/project_patent/upload_import_file", form).then((res) => {
        this.code = res.code;
        console.log(res);
        this.dialogFormVisible = false;
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.getList()
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
  },
}
</script>

<style scoped>
.ImportTop {
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
}
.ChooseFile {
  width: 370px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
}
.ImportTop {
  display: flex;
  align-items: center;
}
</style>